
import { Component, Ref } from 'vue-property-decorator'
import { Datetime, VueDateTimeConstructor } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import ConfirmCosts from '@/pages/project/confirm-costs/class/confirm.costs';

interface Docs { total: number; date: string; group: string }

@Component({
  components: {
    Datetime,
  }
})
export default class ConfirmCostsMonth extends ConfirmCosts{
  private filterGroup: string | null = null;
  private groups: Array<{ userGroup: string; userGroupFullName: string; }> = [];
  private docs: Array<Docs> = [];
  private totalCost = 0;
  @Ref('startDateInput') startDateInput!: VueDateTimeConstructor;

  created () {
    (async () => {
      await this.getGroups();
      await this.getFilter();
      await this.load();
    })();
  }

  private get groupOptions() {
    const groups = this.groups.map(group => ({
      value: group.userGroup,
      text: group.userGroupFullName,
    }));

    return [
      { text: 'All', value: null, },
      ...groups,
    ]
  }

  private getFilter () {
    const { page, filterYear, filterMonth, filter, filterValue, filterGroup } = this.$route.query;
    if (page && !isNaN(+page)) this.pageNumber = +page;
    if (filterYear && !isNaN(+filterYear)) this.filterYear = +filterYear;
    if (filterMonth && !isNaN(+filterMonth)) this.filterMonth = +filterMonth;
    if (filterGroup && typeof filterGroup === 'string') this.filterGroup = filterGroup;
    if (filter && filterValue && typeof filter === 'string' && typeof filterValue === 'string') {
      this.filter = filter;
      this.filterValue = filterValue;
    }
  }

  get qs (): Array<[ string, string | number | null ]> {
    return [
      [ 'filter', this.filter ],
      [ 'filterValue', this.filterValue ],
      [ 'filterYear', this.filterYear ],
      [ 'filterMonth', this.filterMonth ],
      [ 'filterGroup', this.filterGroup ],
    ];
  }

  private async getGroups () {
    const { data: { group } } = await this.axios.get<{ group: { userGroup: string; userGroupFullName: string; }[] }>('/group',{});
    this.groups = group;
  }

  private async load (): Promise<void> {
    try {
      this.pending = true;
      const { data } = await this.axios.get<{ list: Docs[], totalCost: number; }>('/project/confirm-costs/month', {
        params: {
          page: this.pageNumber,
          filter: this.filter,
          filterValue: this.filterValue,
          filterYear: this.filterYear,
          filterMonth: this.filterMonth,
          filterGroup: this.filterGroup,
        }
      });
      const { list, totalCost } = data;
      this.docs = list;
      this.totalCost = totalCost;

    } catch (e) {
      console.log(e);
    } finally {
      this.pending = false;
    }
    // await this.$nextTick();
  }

}
